
export class LanguageOffer extends HTMLElement {
    static constants = {
        STORAGE_KEY: 'langSelected'
    }

    static shouldShowOffer(userIsPolishSpeaker, currentLang) {
        const fromStorage = localStorage.getItem(this.constants.STORAGE_KEY);
        if (fromStorage === 'yes') {
            return;
        }
        console.log(userIsPolishSpeaker, currentLang);
        if (userIsPolishSpeaker && currentLang !== 'pl-PL') {
            return true;
        } else if (!userIsPolishSpeaker && currentLang !== 'en-US') {
            return true;
        }
    }

    connectedCallback() {
        const currentLang = document.querySelector('html').lang;
        const preferredLangs = navigator.languages;
        const userIsPolishSpeaker = preferredLangs.includes('pl') || preferredLangs.includes('pl-PL');

        if (!this.constructor.shouldShowOffer(userIsPolishSpeaker, currentLang)) {
            return;
        }

        setTimeout(() => {
            this.render(currentLang);
        }, 1000);
    }

    render(currentLang) {
        const currentIsPolish = currentLang === 'pl-PL';
        const text =  currentIsPolish ? (
            'English version available / Dostępna także angielska wersja.'
        ) : (
            'Dostępna także polska wersja / Polish version available.'
        )
        const jump = {
            text: currentIsPolish ? 'Switch to English' : 'Zmień na Polski',
            href: currentIsPolish ? '/en' : '/',
            lang: currentIsPolish ? 'en-US' : 'pl-PL'
        };
        const stay = {
            text: currentIsPolish ? 'No, thanks' : 'Nie, dziękuję',
            lang: currentIsPolish ? 'en-US' : 'pl-PL',
        }

        const p = document.createElement('p');
        const buttonJump = document.createElement('a');
        const buttonStay = document.createElement('button');

        p.textContent = text;

        buttonJump.innerText = jump.text;
        buttonJump.href = jump.href;
        buttonJump.lang = jump.lang;
        buttonJump.classList.add('language-selector__button-jump');

        buttonStay.type = 'button';
        buttonStay.classList.add('language-selector__button-stay');
        buttonStay.lang = stay.lang;
        buttonStay.innerText = stay.text;
        buttonStay.addEventListener('click', (e) => {
            e.preventDefault();
            localStorage.setItem(this.constructor.constants.STORAGE_KEY, 'yes');
            this.classList.add('language-selector--close');
            setTimeout(() => {
                this.innerHTML = '';
            }, 1000);
        });

        this.append(p, buttonStay, buttonJump);
    }
}
