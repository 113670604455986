import { LanguageOffer } from "./components/LanguageOffer";

const main = () => {
    const map = document.getElementById('kontakt-mapa');
    const mapListener = () => {
        map.classList.toggle('contact__map--clicked');
    };
    map.addEventListener('click', mapListener);

    if (!customElements.get('language-offer')) {
        customElements.define('language-offer', LanguageOffer);
    }
};

main();
